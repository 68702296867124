import React from "react";
import PropTypes from "prop-types";
import InfoHoverBlock from "components/success/InfoHoverBlock";
import PrimeBonifiee from "components/bonification/PrimeBonifiee";
import PrimeBonifieeTotal from "components/bonification/PrimeBonifieeTotal";
import { getNumericValue, formatEUR } from "utils/number";

export default function PrimeBlock({ APIresult, resultCodePromo, item }) {
  const montantMpr = getNumericValue(APIresult["Montant MPR unitaire (€)"]);
  const montantPrimeCEE = getNumericValue(
    APIresult["Montant prime CEE unitaire (€)"],
  );
  const montantPromo = getNumericValue(resultCodePromo.data.montant_promo);
  const montantBonification = getNumericValue(APIresult.bonification);

  return (
    <div>
      {montantPrimeCEE > 0 && (
        <div
          className={
            montantMpr || montantPromo || montantBonification
              ? "success-item-right no-border"
              : "success-item-right "
          }
        >
          <div>
            <InfoHoverBlock
              montantPrimeCEE={montantPrimeCEE}
              montantMpr={montantMpr}
              item="Prime1"
            />
          </div>
          <div>
            <h5 className="h5-prime whitespace-nowrap">
              - {formatEUR(montantPrimeCEE)}
            </h5>
          </div>
        </div>
      )}
      <PrimeBonifiee montantBonification={montantBonification} />
      <PrimeBonifieeTotal
        montantBonification={montantBonification}
        montantPrimeCEE={montantPrimeCEE}
        montantMpr={montantMpr}
      />

      {montantMpr > 0 && (
        <div>
          <div className="success-item-right no-border">
            <div>
              <InfoHoverBlock
                montantPrimeCEE={montantPrimeCEE}
                montantMpr={montantMpr}
                item="Prime2"
              />
            </div>
            <div>
              <h5
                className="h5-prime whitespace-nowrap"
                style={{ fontSize: "20px" }}
              >
                - {formatEUR(montantMpr)}
              </h5>
            </div>
          </div>
          <div className="w-100">
            {item !== "Prime1" && (
              <div className="success-item-right ">
                <p className="text-sub2 paragraph">
                  <strong>MaPrimeRénov&quot;</strong> est une aide publique dont
                  le montant varie en fonction des revenus et du type de
                  travaux. <strong>MaPrimeRénov&quot;</strong> n’est pas pris en
                  charge par Sorégies.{" "}
                  <a
                    href="https://www.maprimerenov.gouv.fr/"
                    className="text-sub2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Voir le site.
                  </a>
                </p>
              </div>
            )}
          </div>
        </div>
      )}

      {montantPromo > 0 && (
        <div className="success-item-right">
          <div className="info-block-container">
            <p className="p">Code Promo</p>
          </div>
          <div>
            <h5 className="h5-prime whitespace-nowrap">
              - {formatEUR(montantPromo)}
            </h5>
          </div>
        </div>
      )}
    </div>
  );
}
PrimeBlock.propTypes = {
  APIresult: PropTypes.object,
  resultCodePromo: PropTypes.object,
  item: PropTypes.string,
};
