import React, { Component } from "react";
import getEnvironment from "utils/getEnvironment";
const CryptoJS = require("crypto-js");

const {
  apiUrl: ENDPOINT,
  cryptoKey: cryptoKEY,
  apiToken: token,
} = getEnvironment();

class PoitouCheckbox extends Component {
  constructor() {
    super();
    this.state = {
      poitou: false,
      error: "",
      loading: false,
      poitouResult: "",
    };
  }

  handlePoitouChange = (e) => {
    const value = e.target.value === "Oui";
    this.setState({ poitou: value, error: "", poitouResult: "" });

    const bodyData = {
      transaction_id: this.props.result.data.hubspot_transactionId,
      partenariat_poitou: value,
    };
    // console.log('poitoubody', bodyData )
    const bodyData_encoded = {
      data: CryptoJS.AES.encrypt(
        JSON.stringify(bodyData),
        cryptoKEY,
      ).toString(),
    };
    console.log(
      "poitou body crypted",
      bodyData_encoded,
      "poitou body",
      bodyData,
    );
    const url = `${ENDPOINT}/updatetransaction`;
    // console.log('start poitou fetch..')
    this.setState({ loading: true });
    fetch(url, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify(bodyData_encoded),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        return response.json();
      })
      .then((result) => {
        this.setState({ poitouResult: result });
        // console.log('result poitou', result);
      })
      .catch((error) => {
        this.setState({ error: true });
        console.log("error:", error);
        // Handle the error as needed
      })
      .finally(() => {
        this.setState({ loading: false }); // Set loading to false when the request completes (whether successful or with an error)
      });
  };

  render() {
    // console.log('bodyData', this.props.bodyData)
    const chantierPostalCode = this.props.bodyData.postalcode;
    const showPoitou =
      chantierPostalCode.startsWith("86") ||
      chantierPostalCode.startsWith("37");
    // console.log('poitou result', this.state.poitouResult);
    // console.log('body poitou',   {
    //   transaction_id: this.props.result['data'].hubspot_transactionId,
    //   partenariat_poitou: this.state.poitou,
    // })
    // console.log('error message:', this.state.error)
    // console.log('result poitou', this.props.result )
    return showPoitou ? (
      <div className="mb-20">
        <div className="d-flex aic jcc mt-10" style={{ lineHeight: "13px" }}>
          <p className="paragraph">
            Je souhaite bénéficier du partenariat Crédit Agricole Touraine
            Poitou pour financer mes travaux
          </p>

          <div>
            <div className="poitou-box">
              <input
                id="Oui"
                name="poitou"
                value="Oui"
                type="radio"
                checked={this.state.poitou}
                onChange={this.handlePoitouChange}
                disabled={this.state.loading}
              />
              <label
                htmlFor="Oui"
                className={this.state.poitou ? "checked" : ""}
              >
                Oui
              </label>
              <input
                id="Non"
                name="poitou"
                value="Non"
                type="radio"
                checked={!this.state.poitou}
                onChange={this.handlePoitouChange}
                disabled={this.state.loading}
              />
              <label
                htmlFor="Non"
                className={!this.state.poitou ? "checked" : ""}
              >
                Non
              </label>
            </div>
          </div>
        </div>
        {this.state.poitouResult?.success && this.state.poitou && (
          <p className="text-xs text-green align-right">
            Votre demande de mise en relation sera prise en compte par nos
            gestionnaires lors de la constitution de votre dossier
          </p>
        )}

        {this.state.poitouResult?.success && !this.state.poitou && (
          <p className="text-xs text-green align-right">
            Vous ne souhaitez pas bénéficier du partenariat Crédit Agricole
          </p>
        )}

        {this.state.loading && <span className="loader"></span>}

        {this.state.error && !this.state.loading && (
          <p className="text-xs text-red align-right">
            Une erreur s'est produite
          </p>
        )}

        {/* <p className='text-xs mt-5 text-red'>Error!</p> */}
      </div>
    ) : null;
  }
}

export default PoitouCheckbox;
