import http from "./http";

export async function fetchRevenus(postalcode) {
  const apiUrl = http.getApiUrl("hellio-api", "categoriesRevenus/paliersMPR");
  const res = await http.post(apiUrl, {
    code_postal: postalcode,
  });

  const data = Object.values(res.data);
  return data;
}
