import React from "react";

class PrimeBonifieeMention extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const montantBonification = parseFloat(this.props.APIresult?.bonification?.replace(/\s/g, '') || 0);

    // Si montantBonification est <= 0, ne rien rendre
    if (montantBonification <= 0) {
      return null;
    }

    const mention =
        "* Pour tout devis signé entre le 15/01/2025 et le 15/06/2025 et pour tout travaux réalisés et facturés au plus tard le 31/12/2025";
    
    return (
        <p className="paragraph" style={{ fontWeight: "bold" }}>{mention}</p>
    );
  }
}

export default PrimeBonifieeMention;
