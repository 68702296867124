import React from "react";
import { formatEUR } from "utils/number";
import PropTypes from "prop-types";

export default function PrimeBonifieeTotal({
  montantBonification,
  montantPrimeCEE,
  montantMpr,
}) {
  // Si montantBonification est <= 0, ne rien rendre
  if (montantBonification <= 0) {
    return null;
  }

  const containerClass = `success-item-right ${!montantMpr ? "border-b border-[#EDE9E6]" : "no-border"}`;
  const containerStyle = montantMpr ? { marginBottom: 0 } : {};

  return (
    <div className={containerClass} style={containerStyle}>
      <div>
        <p className="text-[20px] font-bold text-rouge-soregies">
          Prime totale
        </p>
      </div>
      <div>
        <h5
          className="h5-prime whitespace-nowrap"
          style={{ fontSize: "20px", backgroundColor: "#F97E73" }}
        >
          - {formatEUR(montantPrimeCEE + montantBonification)}
        </h5>
      </div>
    </div>
  );
}
PrimeBonifieeTotal.propTypes = {
  montantBonification: PropTypes.number,
  montantPrimeCEE: PropTypes.number,
  montantMpr: PropTypes.number,
};
