import React, { useMemo } from "react";
import Navigation from "./Navigation";
import clsx from "clsx";
import { formatPriceEUR } from "utils/formatPrice";
import useFetch from "hooks/useFetch";
import { fetchRevenus } from "services/api";
import Loader from "./Loader";
import PropTypes from "prop-types";

export default function Revenu(props) {
  // Utiliser le code postal du logement si celui de l'addresse fiscal n'existe pas
  const codePostal = props.globalState.fiscalContact.postalcode
    ? props.globalState.fiscalContact.postalcode
    : props.globalState.address.postalcode;

  const { isLoading, data: revenus } = useFetch(
    `Revenus-${codePostal}`,
    async () => {
      return await fetchRevenus(codePostal);
    },
  );

  // Le nombre de personne dans le logement
  const [currentCount, setCurrentCount] = React.useState(
    parseInt(props.revenuPeople, 10) || 1,
  );

  // La liste des intervales de revenus [[0, 23450], ....]
  const currentRevenuRangeList = useMemo(() => {
    if (!revenus) return [];
    return revenus.map((revenu) => revenu[currentCount]);
  }, [revenus, currentCount]);

  // La première valeur du revenu selectionné [23450, 65228] => 23450
  const [selectedRevenu, setSelectedRvenu] = React.useState(
    parseInt(props.globalState.answers.Q19, 10),
  );

  const style = {
    gridTemplateColumns: "repeat(5, 36px)",
  };

  const handleCountChange = (e) => {
    const count = parseInt(e.target.value);
    setCurrentCount(parseInt(count, 10));
    setSelectedRvenu();
    props.handleRevenuPeople(e);
  };

  return (
    <div>
      {isLoading && <Loader />}

      <div className="fadeIn align-center">
        {!isLoading && revenus && currentRevenuRangeList && (
          <div>
            <h4 className="text-main mv-10">
              Nombre de personnes dans votre logement
            </h4>

            <div className="flex items-center justify-center">
              <div
                className="flex-none grid  gap-4 md:flex md:flex-row md:gap-0"
                style={style}
              >
                <RevenuPeopleCountSelector
                  value={currentCount}
                  onChange={handleCountChange}
                />
              </div>
            </div>

            <h4 className="text-main mt-30 mb-10">
              Revenu fiscal de référence
            </h4>
            <div className="flex flex-col space-y-4 md:space-y-0 md:space-x-4 md:flex-row items-center justify-center">
              {currentRevenuRangeList.map((revenu, index) => {
                return (
                  <RevenuSelector
                    revenu={revenu}
                    key={revenu}
                    selectedRevenu={selectedRevenu}
                    onChange={(e) => {
                      setSelectedRvenu(parseInt(e.target.value, 10));
                      props.handleData(e);
                    }}
                  />
                );
              })}
            </div>
          </div>
        )}

        <Navigation
          data={props.data}
          step={props.step}
          nextStep={props.nextStep}
          lastStep={props.lastStep}
        />
      </div>
    </div>
  );
}

function RevenuPeopleCountSelector(props) {
  const { value, onChange } = props;
  const PEOPLE_COUNT = 10;

  return new Array(PEOPLE_COUNT).fill(0).map((_, index) => {
    const item = index + 1;
    return (
      <label
        key={item}
        className={clsx("radio-rounded shrink-0", {
          "radio-rounded-checked": item === value,
        })}
      >
        {item}
        <input
          type="radio"
          value={item}
          name="people"
          checked={item === value}
          onChange={onChange}
        />
      </label>
    );
  });
}

function RevenuSelector(props) {
  const { revenu, selectedRevenu, onChange } = props;

  const formateRevenusValue = (revenu) => {
    if (revenu.length === 1) {
      return `Supérieur à ${formatPriceEUR(revenu[0])}`;
    }

    return `${formatPriceEUR(revenu[0])} à ${formatPriceEUR(revenu[1])}`;
  };

  return (
    <label
      className={clsx("w-full max-w-64 radio-oval md:w-auto md:max-w-auto", {
        "radio-oval-checked": revenu[0] === selectedRevenu,
      })}
    >
      <p>{formateRevenusValue(revenu)}</p>
      <input
        type="radio"
        name="Q19"
        className="hidden"
        value={revenu[0]}
        checked={revenu[0] === selectedRevenu}
        onChange={onChange}
      />
    </label>
  );
}

RevenuSelector.propTypes = {
  revenu: PropTypes.array,
  selectedRevenu: PropTypes.number,
  onChange: PropTypes.func,
};

Revenu.propTypes = {
  globalState: PropTypes.object,
  revenuPeople: PropTypes.string,
  data: PropTypes.object,
  step: PropTypes.number,
  nextStep: PropTypes.func,
  lastStep: PropTypes.func,
  handleData: PropTypes.func,
  handleRevenuPeople: PropTypes.func,
};
