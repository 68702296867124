import React from "react";
import PropTypes from "prop-types";
import { formatEUR } from "utils/number";

export default function PrimeBonifiee({ montantBonification }) {
  // Si montantBonification est <= 0, ne rien rendre
  if (montantBonification <= 0) {
    return null;
  }

  return (
    <div className="success-item-right border-b border-[#EDE9E6]">
      <div>
        <p className="text-[20px] font-bold text-vert-soregies">
          Bonus Sorégies*
        </p>
      </div>
      <div>
        <h5
          className="h5-prime whitespace-nowrap"
          style={{ fontSize: "20px", backgroundColor: "#72D09A" }}
        >
          - {formatEUR(montantBonification)}
        </h5>
      </div>
    </div>
  );
}
PrimeBonifiee.propTypes = {
  montantBonification: PropTypes.number,
};
