export function getNumericValue(strValue) {
  if (!strValue) {
    return 0;
  }
  const numericValue = strValue ? +strValue.replace(/\s/g, "") : 0;
  return !isNaN(numericValue) ? numericValue : 0;
}

export function formatEUR(price, precision = 0) {
  return new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
    maximumFractionDigits: precision ?? 0,
  }).format(price);
}

export const numberToLocaleString = (num, unit = null, precision = 0) => {
  return `${num?.toLocaleString("fr-FR", { maximumFractionDigits: precision ?? 2 })} ${unit || ""}`.trim();
};
