import React from "react";

class BonificationBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {

    const montantBonification = parseFloat(this.props.APIresult?.bonification?.replace(/\s/g, '') || 0);

    // Si montantBonification est <= 0, ne rien rendre
    if (montantBonification <= 0) {
      return null;
    }
    
    return (
      <div className="bg-vert-soregies text-white w-[calc(100%+68px)] -ml-[34px] -mt-[10px] px-2 py-4 mb-2 flex justify-center items-center rounded font-bold leading-3">
        <p>
          Sorégies bonifie sa prime travaux pour tous !
        </p>
      </div>
    );
  }
}

export default BonificationBanner;
